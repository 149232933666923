import React, { Component } from 'react'
import styled from 'styled-components'

export const ComponentWrapper = styled.div`
  background-color: #1b2130;
  color: #fff;
  line-height: 1.3;
  margin: 0 auto;
  max-width: 120rem;
  padding: 12rem 6rem;
  h1 {
    font-size: 5rem;
  }
  h2 {
    font-size: 3rem;
    margin: 4rem 0 0.5rem;
  }
  a {
    text-decoration: underline;
    &:hover {
      color: #fac76a;
    }
  }
  .cky-banner-element {
    background-color: var(--button-color);
    color: #1b2130;
    display: inline-block;
    font-weight: 600;
    padding: 12px 16px;
    margin: 4px 0 14px;
    text-decoration: none;
    text-transform: uppercase;
    &:hover {
      background-color: #1b2130;
      border: 2px solid var(--button-color);
      color: var(--button-color);
      cursor: pointer;
    }
  }
  h3 {
    display: block;
    font-size: 3.5rem;
    font-weight: 700;
    margin: 2rem auto;
    padding: 2rem 0 1rem;
  }
  h5 {
    font-size: 2.8rem;
    margin-bottom: 20px;
  }
  .last-updated {
    font-style: italic;
    margin-bottom: 3rem;
  }
  li {
    margin: 1rem 0;
    padding-left: 1rem;
    & > strong {
      margin-right: 0.3rem;
    }
  }
  i {
    font-style: italic;
  }
  p {
    font-size: 1.6rem;
  }
  strong {
    font-weight: 700;
  }
  ul {
    margin-left: 1.8rem;
    &.bullets {
      list-style-type: disc;
      margin-inline-start: 3rem;
    }
  }
  .underline {
    text-decoration: underline;
  }
`

class CookiePolicyPage extends Component {
  constructor() {
    super()
  }

  render() {
    return (
      <ComponentWrapper>
        <h1>Cookie Policy</h1>
        <p className="last-updated">Last updated on July 19, 2024</p>
        <p>
          Hi-Rez Studios, Inc. and its affiliates (“we” “us” “our”) values your
          privacy and are committed to protecting your personal data. For more
          information on our privacy practices generally please visit our
          Privacy Policy. Any changes in this Cookie Policy will become
          effective on the dated indicated as “Last Updated.” The names and
          functionality of cookies pixels and other technologies may change over
          time.
        </p>
        <p>
          This Cookie Policy applies to any website that links to this policy
          and explains what cookies are how we use cookies on our websites your
          choices regarding cookies and further information about cookies.
        </p>
        <h2>Cookie Policy Table of Contents:</h2>
        <ul>
          <li>
            <a href="#what-is-a-cookie">What is a cookie?</a>
          </li>
          <li>
            <a href="#how-and-when-do-we-use-cookies">
              How and when do we use cookies?
            </a>
          </li>
          <li>
            <a href="#what-cookies-are-used-on-this-website">
              What cookies are used on this website?
            </a>
          </li>
          <li>
            <a href="#how-are-cookies-used-for-advertising-purposes">
              How are cookies used for advertising purposes?
            </a>
          </li>
          <li>
            <a href="#how-do-i-manage-my-cookie-preferences">
              How do I manage my cookie preferences?
            </a>
          </li>
          <li>
            <a href="#where-can-i-learn-more-about-cookies">
              Where can I learn more about cookies?
            </a>
          </li>
        </ul>
        <h3 id="what-is-a-cookie">What is a cookie?</h3>
        <p>
          A cookie is a small file placed onto your device that enables a
          variety of features and functionality. Cookies also enable you to
          easily share content and help us serve relevant ads to you. Cookies
          can do this because websites can read and write them enabling websites
          to recognize you and remember important information that will make
          your use of a website more convenient (for example by remembering
          preference settings).
        </p>
        <h3 id="how-and-when-do-we-use-cookies">
          How and when do we use cookies?
        </h3>
        <p>
          We use cookies and other technologies to enable certain functions of
          the Website, provide analytics, store your preferences, and keep your
          account safe.
        </p>
        <p>We use a variety of cookie types:</p>
        <ul>
          <li>
            <strong>Strictly Necessary Cookies.</strong> Strictly necessary
            cookies are necessary for the website to work and to enable you to
            move around it and to use its features. Without these cookies,
            websites will not perform as intended and we may not be able to
            provide our website or certain services or features you request.
          </li>
          <li>
            <strong>Functionality Cookies.</strong> Functionality cookies
            collect information about your choices and preferences and allow us
            to remember language or other local settings and customize the
            websites accordingly.
          </li>
          <li>
            <strong>Analytics Cookies.</strong> Analytics cookies collect
            information about your use of the websites and enable us to improve
            the way they work. For example, analytics cookies show us which are
            the most frequently visited pages on a website, help us record any
            difficulties you have with a website, and show us whether our
            advertising is effective or not. This allows us to see the overall
            patterns of usage rather than the usage of a single person.
          </li>
          <li>
            <strong>Advertising Cookies.</strong> Advertising cookies are used
            for marketing purposes. This is discussed below in greater detail.
          </li>
        </ul>
        <p>
          All of the cookies above are either persistent cookies or session
          cookies. A persistent cookie helps us recognize you as an existing
          user so it’s easier to return to the website or interact with our
          services repeatedly. A persistent cookie stays in your browser and
          will be read by the website when you return to one of our websites or
          a partner site that uses our services. Session cookies only last for
          as long as the session (usually the current visit to a website or a
          browser session).
        </p>
        <h3 id="what-cookies-are-used-on-this-website">
          What cookies are used on this site?
        </h3>
        <div className="cky-audit-table-element"></div>
        <h3 id="how-are-cookies-used-for-advertising-purposes">
          How are cookies used for advertising purposes?
        </h3>
        <p>
          Cookies and other ad technology such as beacons, pixels, and tags help
          us serve relevant ads to you more effectively. They also help us
          provide aggregated auditing, research, and reporting for advertisers,
          understand and improve our service, and know when content has been
          shown to you. We may use third party advertising companies to serve
          ads when you visit our website. These companies may employ cookies and
          clear GIFs to measure advertising effectiveness. We encourage you to
          read the privacy policies of these third party advertising companies
          if you have any concerns. Note: Because your web browser may request
          advertisements and beacons directly from third party ad network
          servers, these networks can view, edit, or set third party cookies
          just as if you had requested a web page from their site. Ads served by
          us may also set third party cookies.
        </p>
        <p>
          Unless you clear these cookies from your browser we may use this
          information to:
        </p>
        <ul className="bullets">
          <li>provide more relevant interest-based advertising</li>
          <li>
            provide aggregate reports of ads activity to advertisers and
            websites hosting the ads
          </li>
          <li>
            help website and app owners understand how visitors engage with
            their sites or apps
          </li>
          <li>
            detect and defend against fraud and other risks to protect users and
            partners
          </li>
          <li>improve our products</li>
        </ul>
        <h3 id="how-do-i-manage-my-cookie-preferences">
          How do I manage my cookie preferences?
        </h3>
        <h5>Manage cookie preferences</h5>
        <a className="cky-banner-element">Cookie Settings</a> <br />
        <p>
          You can change your cookie preferences any time by clicking the above
          button. This will let you revisit the cookie consent banner and change
          your preferences or withdraw your consent immediately. In compliance
          with applicable regulations, “Necessary Cookies” are necessary for the
          website to function, are not subject to your consent, and can
          therefore not be deactivated in our cookie management tool.
        </p>
        <p>
          In addition to this, most browsers allow you to control and erase
          cookies through their settings preferences. However, if you limit the
          ability of websites to set cookies, you may negatively impact your
          overall user experience since it will no longer be personalized to
          you. Additionally, some features may work improperly or not at all and
          it may stop you from saving customized settings.
        </p>
        <p>
          Browser manufacturers provide help pages relating to cookie management
          in their products. Please see below for more information.
        </p>
        <ul>
          <li>
            <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en">
              Google Chrome
            </a>
          </li>
          <li>
            <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
              Safari (Desktop)
            </a>
          </li>
          <li>
            <a href="https://support.apple.com/en-us/HT201265">
              Safari (Mobile)
            </a>
          </li>
          <li>
            <a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox">
              Mozilla Firefox
            </a>
          </li>
          <li>
            <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">
              Microsoft Edge
            </a>
          </li>
        </ul>
        <h3 id="where-can-i-learn-more-about-cookies">
          Where can I learn more about cookies?
        </h3>
        <p>
          To find out more about cookies, including how to see what cookies have
          been set and how to manage and delete them, visit{' '}
          <a href="http://www.allaboutcookies.org">www.allaboutcookies.org</a>{' '}
          or <a href="http://www.aboutcookies.org">www.aboutcookies.org</a>.
        </p>
        <p>
          To learn more about advertisers’ use of cookies, the following links
          may be helpful:
        </p>
        <ul>
          <li>
            <a href="http://www.edaa.eu">
              European Interactive Digital Advertising Alliance (EU)
            </a>
          </li>
          <li>
            <a href="http://www.iab.net">Internet Advertising Bureau (US)</a>
          </li>
          <li>
            <a href="http://www.iabeurope.eu">
              Internet Advertising Bureau (EU)
            </a>
          </li>
        </ul>
        <p>You may also contact us at:</p>
        <p>
          Hi-Rez Studios
          <br />
          Attn: Legal Department
          <br />
          Email:{' '}
          <a href="mailto:legal@hirezstudios.com">legal@hirezstudios.com</a>
          <br />
          Legal Department
          <br />
          Hi-Rez Studios Inc.
          <br />
          4300 Alexander Drive Suite 100B
          <br />
          Alpharetta, Georgia 30022
        </p>
      </ComponentWrapper>
    )
  }
}

export default CookiePolicyPage
